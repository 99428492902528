.category-container {
  position: relative;
  /* margin-top: 300px; */
  /* height: 500px; */
}

.category-bar {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}

.category-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-item {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
}

.category-content-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.category-content {
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 1600px;
  z-index: 1;
  position: absolute;
  top: 100%;
}

.category-content.show {
  display: block;
}

.category-content ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.category-content li {
  padding: 5px 10px;
}  