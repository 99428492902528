.shadow-bg{
    box-shadow: 2px 8px 8px 4px #f65e00;
}

.fixed-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #ff4500;
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  