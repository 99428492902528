.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.bg-color-one {
  background: #f65e00;
}
.carousel {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%;
  height: 50vh;
}
@media screen and (min-width: 360px) {
  .slide {
    height: 20vh;
  }
  .left-position {
    left: 30vh;
  }
  .input-one {
    width: 40vh;
  }
  .w-logo {
    width: 49vh;
  }
}
@media screen and (min-width: 390px) {
  .left-position {
    left: 30vh !important;
  }
  .input-one {
    width: 38vh !important;
  }
  .w-logo {
    width: 47vh !important;
  }
}
@media screen and (min-width: 375px) {
  .slide {
    height: 20vh;
  }
  .left-position {
    left: 35vh;
  }
  .input-one {
    width: 45vh;
  }
  .w-logo {
    width: 56vh;
  }
}
@media screen and (min-width: 412px) {
  .w-logo{
    width: 45vh !important;
  }
}
@media screen and (min-width: 414px) {
  .slide {
    height: 20vh;
  }
  .left-position {
    left: 32vh !important;
  }
  .input-one {
    width: 40vh !important;
  }
  .w-logo{
    width: 46vh !important;
  }
}

@media screen and (min-width: 430px) {
  .slide {
    height: 20vh;
  }
  .w-logo{
    width: 46vh !important;
  }
}
@media screen and (min-width: 540px) {
  .slide {
    height: 30vh;
  }
  .input-one {
    width: 65vh !important;
  }
  .left-position {
    left: 55vh !important;
  }
  .w-logo {
    width: 75vh !important;
  }
}
@media screen and (min-width: 912px) {
  .slide {
    height: 30vh;
  }
}
@media screen and (min-width: 1024px) {
  .slide {
    height: 65vh;
  }
}
@media screen and (min-width: 1280px) {
  .slide {
    height: 50vh;
  }
}

.active {
  opacity: 1;
  transform: translateX(0);
}

.prev {
  opacity: 0;
  transform: translateX(-100%);
}

/* Next slide */
.next {
  opacity: 0;
  transform: translateX(100%);
}

button:hover {
  color: #ccc;
}
/* .my-nav {
  z-index: 10;
  width: 100%;
  position: absolute;
} */

.height-one {
  width: 50vh;
}

@media screen and (max-width: 780px) {
  .button-bar {
    position: relative;
    z-index: 9999;
  }
}
