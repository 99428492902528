.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.w1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.w4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.h5{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
}

/* .bg-gray-lighter {
  --tw-bg-opacity: 1;
  background-color: rgb(228 242 242 / var(--tw-bg-opacity));
} */

.text-cart{
  font-size: 13px;
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: rgba(0, 0, 0, 0.7);
}

.custom-radio input[type="radio"] {
  appearance: none; /* Remove default radio button appearance */
  width: 1rem; /* Size of the radio button */
  height: 1rem; /* Size of the radio button */
  border-radius: 50%; /* Make it circular */
  border: 1px solid #000; /* Border color */
  position: relative; /* For positioning the inner circle */
  background-color: #fff; /* Background color of the radio button */
}

.custom-radio input[type="radio"]:checked::before {
  content: ""; /* Add content for the inner circle */
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  width: 50%; /* Size of the inner circle */
  height: 50%; /* Size of the inner circle */
  border-radius: 50%; /* Make it circular */
  background-color: #000; /* Inner circle color */
  transform: translate(-50%, -50%); /* Center the inner circle */
}


