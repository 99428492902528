.category-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.category-bar {
  display: flex;
  overflow-x: auto;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
  min-height: 50px;
}

.category-list {
  display: flex;
  list-style-type: none;
  background-color: #ffffff;
}

.category-list:hover {
  background-color: #272727;
  color: #ddd;
}

.category-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.grid-item:hover {
  background-color: #d3d3d3;
  /* color: rgb(56, 56, 56); */
}

.category-item:hover {
  background-color: #ddd;
  color: #272727;
}

.category-content-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  /* background-color: #272727; */
}

.category-content {
  display: none;
  width: 100%;
}

.category-content.show {
  display: block;
  padding: 1rem;
}

@media (max-width: 768px) {
  .category-bar {
    flex-direction: column;
  }

  .category-item {
    text-align: center;
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .category-content {
    display: block !important;
    padding: 0.5rem;
    visibility: hidden;
    opacity: 0;
  }

  .category-content.show {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .category-item {
    font-size: 0.8rem;
    padding: 0.5rem 0.2rem;
  }

  .category-content {
    padding: 0.5rem;
    visibility: hidden;
    opacity: 0;
  }

  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-item {
  padding: 10px;
  text-align: center;
}

@media (min-width: 640px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.category-container {
  position: relative;
}

.loading-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #020202, #525252 50%, transparent 50%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.category-item.selected,
.category-item:hover {
  background-color: #ddd;
  color: #272727;
}