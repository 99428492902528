.form-checkbox {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid #222;
  background-color: #eee;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s;
}

.form-checkbox:checked::before {
  content: "";
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #222;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.w1{
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.w4{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.custom {
  color: #eee;
}

.custom-select option {
  padding: 4px 8px;
  font-size: 14px;
  height: 8px;  
}



/* Buttons Section */
.scrollbar-thin::-webkit-scrollbar {
  height: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.grid-container {
  display: grid;
  gap: 1rem;
}

.grid-item {
  display: flex;
  justify-content: center;
}

.show-more-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}


.custom-label {
  font-size: 10px;
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 640px) {
  .custom-label {
    font-size: 10px;
  }
}

@media (min-width: 1024px) {
  .custom-label {
    font-size: 12px;
  }
}

@media (min-width: 1280px) {
  .custom-label {
    font-size: 12px;
  }
}

.text-css{
  /* text-transform: uppercase; */
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* color: #fff */
}

.custom-label:focus {
  color: white;
  background-color: #000;
}

.custom-radio input[type="radio"] {
  appearance: none; /* Remove default radio button appearance */
  width: 1rem; /* Size of the radio button */
  height: 1rem; /* Size of the radio button */
  border-radius: 50%; /* Make it circular */
  border: 1px solid #000; /* Border color */
  position: relative; /* For positioning the inner circle */
  background-color: #fff; /* Background color of the radio button */
}

.custom-radio input[type="radio"]:checked::before {
  content: ""; /* Add content for the inner circle */
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  width: 50%; /* Size of the inner circle */
  height: 50%; /* Size of the inner circle */
  border-radius: 50%; /* Make it circular */
  background-color: #000; /* Inner circle color */
  transform: translate(-50%, -50%); /* Center the inner circle */
}