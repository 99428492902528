@media screen and (max-width:1280px) {
    .width-three{
    width: 80vh ;
    }
    .width-two{
        width: 50vh ;
        }
    
}
@media screen and (max-width:1024px) {
    
    .width-two{
        width: 60vh !important;
        }
    
}
@media screen and (max-width:912px) {
    .width-two{
    width: 30vh !important;
    }
    
} 

@media screen and (max-width:820px) {
    .width-two{
    width: 40vh !important;
    }
    
} 
@media screen and (max-width:768px) {
    .width-two{
    width: 40vh !important;
    }
    
} 
@media screen and (max-width:540px) {
    .width-two{
    width: 60vh !important;
    }
    
} 
@media screen and (max-width:430px) {
    .width-two{
    width: 40vh !important;
    }
    
}
@media screen and (max-width:414px) {
    .width-two{
    width: 40vh !important;
    }
    
}
@media screen and (max-width:412px) {
    .width-two{
    width: 40vh !important;
    }
    
}

@media screen and (max-width:375px) {
    .width-two{
    width: 50vh !important;
    }
    
}
@media screen and (max-width:360px) {
    .width-two{
    width: 45vh !important;
    }
    
}


.text-modal{
    font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.7);
    font-size: 24px;
    font-weight: 500;
  }