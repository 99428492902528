.border-one {
  border-radius: 10% 35% 37% 10%;
}
.bg-color{
    background:#ff883e;
}
.component-one {
  transition: 0.5s;
  overflow: hidden;
  position: relative;
  width: 48vh;
  height: 70px;

  box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
}
.component-one:hover {
  letter-spacing: 0.35px;
}

.register-modal {
  z-index: 99999;
}

.component-one p {
  position: relative;
  z-index: 2;
  overflow: hidden;
}
.component-one::before {
  content: "";
  position: absolute;
  width: 140%;
  height: 140%;
  background: linear-gradient(#00ccff, #d500f9);
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.component-one::after {
  content: "";
  position: absolute;
  inset: 2px;
  background:#db9c74;
 
}

@media screen and (max-width:1280px) {
  .width-three{
    width: 100vh !important;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 52vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 60vh;
  }
  .text-one{
    padding-left: 0px;
    text-align: center;
    /* width: vh; */
  }
}
@media screen and (max-width:1024px) {
  .width-three{
    width: 200vh;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 52vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 60vh;
  }
  .text-one{
    padding-left: 0px;
    text-align: center;
    /* width: vh; */
  }
}
@media screen and (max-width:912px) {
  .width-three{
    width: 40vh !important ;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 37vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 30vh;
    margin: auto;
  }
  .text-one{
    padding-left: 0px;
    text-align: center;
    width: 40vh;
  }
}
@media screen and (max-width:853px) {
  .width-three{
    width: 32vh !important;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 28vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 35vh;
  }
  .text-one{
    padding-left: 0px;
    text-align: center;
    width: 28vh;
  }
}

@media screen and (max-width:820px) {
  .width-three{
    width: 45vh !important;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 42vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 35vh;
  }
  .text-one{
    padding-left: 0px;
    text-align: center;
    width: 45vh;
  }
}

@media screen and (max-width:768px) {
  .width-three{
    width: 45vh !important;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 40vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 35vh;
  }
  .text-one{
    width: 45vh;
    text-align: center;
    justify-self: center;
  }
}
@media screen and  (max-width:540px){
  .width-three{
    width: 69vh !important;
  }
  .height-three{
    height: 45vh;
  }
  .text-one{
    width: 70vh;
    text-align: center;
    justify-self:center;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 65vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
    margin-left: 8px;
  }
  
}


@media screen and  (max-width:430px){
  .width-three{
    width: 40vh !important;
  }
  .height-three{
    height: 35vh;
  }
  .text-one{
    width: 40vh;
    text-align: center;
    justify-self:center;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 38vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  
}


@media screen and (max-width:414px) {
  .width-three{
    width: 40vh !important;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 38vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 40vh;
  }
  .text-one{
   
    justify-content: center;
    width: 40vh;
    text-align: center;
  }
}
@media screen and (max-width:375px) {
  .width-three{
    width: 50vh !important;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 47vh;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
  }
  .height-three{
    height: 50vh !important;
  }
  .text-one{
    justify-content: center;
    width: 50vh;
    text-align: center;
  }
}
@media screen and (max-width:360px) {
  .width-three{
    width: 45vh !important ;
  }
  .component-one {
    transition: 0.5s;
    overflow: hidden;
    position: relative;
    width: 41vh;
    margin-left: 10px;
    height: 70px;
    box-shadow: 10px 30px 30px 0px rgb(119, 66, 66);
   
  }
  .height-three{
    height: 45vh;
  }
  .text-one{
    width: 45vh;
    justify-content: center;
    text-align: center;
  }
}
