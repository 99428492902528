.name-text {
  font-family: Roboto-Regular, "Helvetica Neue", Helvetica, Tahoma, Arial,
    Sans-serif;
}

/* .order-page {
  padding: 5px;
  width: 95%;
  margin: auto;
} */

@media screen and (max-width: 640px) {
  .order-page {
    margin-top: 10px;
    margin-bottom: 50px;
    width: 95%;
  }
}

